import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import cir from "../../images/custom-web-application/cir.svg"
import flowDot from "../../images/it-staff/flowDot.svg"
import for1 from "../../images/it-staff/for.svg"
import dots from "../../images/it-staff/Group1.png"
import Group2 from "../../images/it-staff/Group2.png"
import dots2 from "../../images/it-staff/Group4.png"
import renRec from "../../images/it-staff/renRec.svg"
import staffCir from "../../images/it-staff/staffCir.svg"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import * as styles from "./MakeCapital.module.scss"

const MakeCapital = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <div className={styles.for1}>
        <img src={for1} decoding="async" loading="lazy" alt="for" />
      </div>
      <div className={styles.cir}>
        <img decoding="async" loading="lazy" src={cir} alt="ccircle" />
      </div>
      <div className={styles.dots1}>
        <img decoding="async" loading="lazy" src={dots2} alt="dots" />
      </div>
      <div className={styles.reactangle}>
        <img decoding="async" loading="lazy" src={Frame} alt="frame" />
      </div>
      <div className={styles.Group}>
        <img decoding="async" loading="lazy" src={Group2} alt="background" />
      </div>
      <div className={styles.flowdot}>
        <img src={flowDot} decoding="async" loading="lazy" alt="dots" />
      </div>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col xl={4} lg={6} md={6} xs={12} key={i} className="gap-30">
              <div className={styles.card}>
                <div className={styles.bgImg}>
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    alt={e?.image1[0]?.alternativeText}
                    decoding="async"
                    loading="lazy"
                  />
                </div>
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.description?.description,
                  }}
                />
              </div>
            </Col>
          ))}
          <div className={styles.dots2}>
            <img src={dots} decoding="async" loading="lazy" alt="dots" />
          </div>
        </Row>
        <div className={styles.renrec}>
          <img src={renRec} decoding="async" loading="lazy" alt="" />
        </div>
        <div className={styles.staffcir}>
          <img src={staffCir} decoding="async" loading="lazy" alt="rectangle" />
        </div>
        <div className={styles.dots4}>
          <img decoding="async" loading="lazy" src={dots} alt="dots" />
        </div>
        <div className={styles.hireBtn}>
          {strapiData?.buttons[0] && (
            <Link
              to={strapiData?.buttons[0]?.url}
              className="btn_black_border_banner"
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
}

export default MakeCapital
