import React, { useRef, useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import left from "../../images/softwere-development/left.png"
import ratingStars from "../../images/softwere-development/rating-stars.png"
import right from "../../images/softwere-development/Right.png"
import clintsDots from "../../images/it-staff/clintsDots.svg"
import clintrec from "../../images/it-staff/clintrec.svg"
import clintsDots2 from "../../images/it-staff/clintsDots2.svg"
import clintcir from "../../images/it-staff/clintcir.svg"
import * as styles from "./Testimonials.module.scss"
import "../../components/common/sliderNewDotsSoft.scss"

const Testimonials = ({ strapiData }) => {
  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.imgBtn} onClick={onClick}>
        <div className={styles.personBg}>
          <img src={left} alt="left arrow" decoding="async" loading="lazy" />
        </div>
        <button>
          <img src={props?.img} decoding="async" loading="lazy" alt="dotted" />
        </button>
      </div>
    )
  }

  function NextArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.imgBtn} onClick={onClick}>
        <div className={styles.personBg}>
          <img src={right} alt="right arrow" decoding="async" loading="lazy" />
        </div>
        <button>
          <img
            src={props?.img}
            decoding="async"
            loading="lazy"
            alt="background"
          />
        </button>
      </div>
    )
  }

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const settings = {
    dots: true,
    dotsClass: "new-dots-soft",
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    beforeChange: (current, next) => setCurrent(next),
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  return (
    <section className={styles.section}>
      <div className={styles.clintDots2}>
        <img src={clintsDots2} decoding="async" loading="lazy" alt="dots" />
      </div>
      <div className={styles.clintRec}>
        <img src={clintrec} decoding="async" loading="lazy" alt="dots" />
      </div>
      <div className={styles.clintCir}>
        <img src={clintcir} decoding="async" loading="lazy" alt="circle" />
      </div>
      <Container>
        <div className={styles.testimonialsContent}>
          {/* <p>Testimonials</p> */}
          <div>
            <h2>What Our Amazing Clients Have To Say</h2>
          </div>
          <div className="pt-2 pt-md-0">
            <a
              href="https://clutch.co/profile/invozone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="btn_black_border mt-4">
                Trusted By Top Companies
              </Button>
            </a>
          </div>
        </div>
        <Row>
          <Col lg={2} className="user-select-none">
            <div className={styles.personBg}></div>
            <PrevArrow
              onClick={slide1?.current?.slickPrev}
              img={
                current === 0
                  ? strapiData?.cards[strapiData?.cards?.length - 1]?.image1[0]
                      ?.localFile?.publicURL
                  : strapiData?.cards[current - 1]?.image1[0]?.localFile
                      ?.publicURL
              }
            />
          </Col>
          <Col lg={8}>
            <Slider {...settings} ref={slide1}>
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => (
                  <div key={i}>
                    <Row className={`${styles.row} m-0`}>
                      <Col md={4} className="pl-0">
                        <img
                          className={styles.person}
                          src={e?.image1[0]?.localFile?.publicURL}
                          alt={e?.image1[0]?.alternativeText}
                          decoding="async"
                          loading="lazy"
                        />
                      </Col>
                      <Col md={8} className={styles.content}>
                        <div className="mb-3">
                          <img
                            src={ratingStars}
                            decoding="async"
                            loading="lazy"
                            alt="stars"
                          />
                        </div>
                        <div
                          className="font-weight-normal mb-5"
                          dangerouslySetInnerHTML={{
                            __html: e?.description?.description,
                          }}
                        ></div>
                        <p className={styles.testName}>{e?.title}</p>
                      </Col>
                    </Row>
                  </div>
                ))}
            </Slider>
          </Col>
          <Col lg={2} className="user-select-none">
            <div className={styles.personBg}></div>
            <NextArrow
              onClick={slide1?.current?.slickNext}
              img={
                current === strapiData?.cards?.length - 1
                  ? strapiData?.cards[0]?.image1[0]?.localFile?.publicURL
                  : strapiData?.cards[current + 1]?.image1[0]?.localFile
                      ?.publicURL
              }
            />
          </Col>
        </Row>
      </Container>
      <div className={styles.clintsdots}>
        <img src={clintsDots} decoding="async" loading="lazy" alt="dots" />
      </div>
    </section>
  )
}

export default Testimonials
