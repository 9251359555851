import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TechnologiesUse.module.scss"

const Technologies = ({ strapiData, bg }) => {
  return (
    <div
      className={styles.advance}
      style={{ backgroundColor: bg ? "#F8F8F8" : "#FFFFFF" }}
    >
      <Container>
        <div className={styles.techBg}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div className={styles.cardsRight}>
            <div className={styles.techIcon}>
              {strapiData?.cards.map(el => (
                <div key={el?.title}>
                  <div className={`${styles.iconssCard}`}>
                    <div className={styles.technologyIcon}>
                      <div className={styles.techImg}>
                        <div>
                          <img
                            decoding="async"
                            loading="lazy"
                            alt={el?.image1[0]?.alternativeText}
                            src={el?.image1[0]?.localFile?.publicURL}
                          />
                        </div>
                      </div>
                      <h3>{el?.title}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Technologies
